<template>
	<div id="app" v-if="imgList.length > 0">
		<div :style="{ width: width + 'rem', height: height, 'border-radius': borderradius }" class="SwiperBox" ref="SwiperBox"
			@mouseenter="MouseFun('移入')" @mouseleave="MouseFun('移出')" @touchstart="touchStart" @touchend="touchEnd"
			@click.stop="juppm(imgList[imgShow])">
			<!-- 图片 -->
			<div class="imgBox" :style="{ height: height, left: `-${leftVal}rem`, transition: `${ition}s` }">
				<div :style="{ width: width + 'rem', height: height }" v-for="(item, index) in imgList" :key="index">
					<img style="width: 100%;height: 100%;" :src="item.imgUrl"  @click.stop="jump(item.id)" />
				</div>
				<!-- 复制第一张放到最后,以实现无缝无线循环滚动效果 -->
				<div :style="{ width: width + 'rem', height: height, }">
					<img style="width: 100%;height: 100%;" :src="imgList[0].imgUrl" alt="" @click.stop="jump(imgList[0].id)">
				</div>
			</div>
			<!-- 左箭头按钮 -->
			<div class="leftBtn" @click="throttle(PrevFun)">
				<img src="../assets/eventtheme/leftBtn.png" alt="">
			</div>
			<!-- 右箭头按钮 -->
			<div class="rightBtn" @click="throttle(NextFun)">
				<img src="../assets/eventtheme/rightBtn.png" alt="">
			</div>
			<!-- 下方指示点容器 -->

			<div class="instBox">
				<div class="instBox_back">
					<div class="instBox_text">
						<div class="text" v-for="(item, index) in imgList" :key="index" v-if="imgShow == index"
							@click.stop="jump(item.id)">
							{{ item.title }}
						</div>
					</div>
					<div class="instBox_text1" :style="{ 'justify-content': 'center', 'padding-right': '' }">
						<div @click.stop="instFun(index)" v-for="(item, index) in imgList.length" :key="index"
							:class="['inst', index == imgShow ? 'instActv' : '']"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		imgList: {
			type: Array,
			default: () => []
		},
		width: {
			type: [String, Number],
			default: '12.97'
		},
		height: {
			type: String,
			default: '380PX'
		},
		borderradius: { // 圆角
			type: String,
			default: '0'
		},
		background: { // 文字区域背景颜色
			type: String,
			default: 'rgba(7, 84, 156, 0.8)'
		},
		type: { // 轮播图样式
			type: Number,
			default: 0
		}
	},
	data() {
		return {
			leftVal: 0, // 轮播图盒子的偏移值
			flag: true, // 用来节流防止重复点击
			start: 4000, // 自动执行下一张定的时器
			imgWidth: this.width, // 在这里填写你需要的图片宽度
			ition: 0.8, // 设置轮播图过度时间
			imgShow: 0, // 表示当前显示的图片索引

			touchStartX: 0,
			touchEndX: 0,
		};
	},
	mounted() {
		this.inft()
	},
	methods: {
		touchStart(event) { // 触摸开始时的位置
			this.touchStartX = event.touches[0].clientX;
		},

		touchEnd(event) { // 触摸结束时的位置
			this.touchEndX = event.changedTouches[0].clientX;
			this.handleSwipe();
		},
		handleSwipe() {
			const swipeDistance = this.touchEndX - this.touchStartX;
			const swipeThreshold = 10; // 较小的值会增加敏感度

			if (swipeDistance > swipeThreshold) {
				this.throttle(this.PrevFun); // 向前滑动
			} else if (swipeDistance < -swipeThreshold) {
				this.throttle(this.NextFun); // 向后滑动
			}
		},
		// 这里定义一个鼠标移入移出事件，鼠标悬停时停止自动轮播，鼠标移出则重新执行自动轮播
		MouseFun(type) { // 停止定时器            // 重新执行定时器
			type == '移入' ? clearTimeout(this.start) : this.inft()
		},
		// 此为自动轮播定时器
		inft() {
			this.start = setInterval(() => {
				this.NextFun()
			}, 4000)
		},
		// 这里通过额外封装的节流函数触发 PrevFun() 和 NextFun(),以达到防止重复点击的效果
		throttle(fun) {
			if (this.flag) {
				this.flag = false;
				fun(); // 此为模板中传递进来的PrevFun()或NextFun()函数
				setTimeout(() => {
					this.flag = true;
				}, 1200); // 节流间隔时间
			}
		},
		// 上一张
		PrevFun() {
			if (this.leftVal == 0) { // 判断显示的图片 是 第一张时执行
				// this.imgList.length是指循环图片数组的图片个数
				this.ition = 0 // 将过渡时间变成0，瞬间位移到最后一张图
				this.imgShow = this.imgList.length - 1 // 将高亮小点改为最后一张图
				this.leftVal = (this.imgList.length) * Number(this.imgWidth) // 瞬间移动
				setTimeout(() => { // 通过延时障眼法,归原过渡时间,执行真正的“上一张”函数
					this.ition = 0.8
					this.leftVal -= Number(this.imgWidth)
				}, this.ition * 1000)
			} else { // 判断显示的图片 不是 第一张时执行
				this.ition = 0.8
				this.leftVal -= Number(this.imgWidth)
				this.imgShow--
			}
		},
		// 下一张
		NextFun() {
			if (this.leftVal == (this.imgList.length - 1) * Number(this.imgWidth)) { // 判断显示的图片 是 最后一张时执行
				this.ition = 0
				this.leftVal += Number(this.imgWidth)
				this.imgShow = 0
				setTimeout(() => {
					this.ition = 0
					this.leftVal = 0
				}, this.ition * 1000)
			} else { // 判断显示的图片 不是 最后一张时执行
				this.ition = 0.8
				this.leftVal += Number(this.imgWidth)
				this.imgShow++
			}
		},
		// 点击小圆点
		instFun(index) {
			this.ition = 0.8
			this.leftVal = index * Number(this.imgWidth)
			this.imgShow = index
		},
		jump(row) {
			this.$emit('getclick', row)
		},
		juppm(row) {
			this.$emit('getclickm', row)
		}
	}
};
</script>

<style lang="scss" scoped>
/* 图片容器样式 */
.SwiperBox {
	position: relative;
	width: 652PX;
	height: 380PX;
	box-sizing: border-box;
	cursor: pointer;
	overflow: hidden;
}

.imgBox {
	position: absolute;
	top: 0PX;
	left: 0PX;
	min-width: 652PX;
	height: 380PX;
	display: flex;
	justify-content: flex-start;
}

/* 图片默认样式 */
.imgBox img {
	flex-shrink: 0;
	width: 652PX;
	height: 380PX;
}

/* 两个按钮共有的样式,也可直接使用箭头图片替代 */
.leftBtn {
	position: absolute;
	top: 43%;
	transform: translateY(-50%);
	width: 0.67rem;
	height: 0.67rem;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	border-radius: 50%;
	cursor: pointer;
	font-size: 12PX;
	font-weight: 500;
	left: 0.4rem;
}

.rightBtn {
	position: absolute;
	top: 43%;
	transform: translateY(-50%);
	width: 0.67rem;
	height: 0.67rem;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	border-radius: 50%;
	cursor: pointer;
	font-size: 12PX;
	font-weight: 500;
	right: 0.4rem;
}

/* 下方指示器盒子 */
.instBox {
	width: 100%;
	height: 1.01rem;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: 0;
	display: flex;
}

.instBox_back {
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	height: 1.01rem;
	background: linear-gradient(0deg, rgba(47, 204, 255, 0.8), rgba(138, 210, 255, 0.8));
}

.instBox_text {
	display: flex;
	opacity: 1;
}

.text {
	font-size: 0.29rem;
	font-family: Microsoft YaHei;
	font-weight: 400;
	color: #FFFFFF;

	/* width: 652PX; */
	padding: 0 20PX;
	margin-top: 13PX;
	text-align: center;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	-o-text-overflow: ellipsis;
	width: 100%;
	margin-top: 0.19rem;
}

.instBox_text1 {
	display: flex;
	justify-content: center;
}

/* 小圆点 */
.inst {
	width: 0.19rem;
	height: 0.19rem;
	background: #FFFFFF;
	border-radius: 50%;
	margin-right: 8PX;
	border-radius: 50%;
	cursor: pointer;
	margin-top: 0.18rem;
}

.inst:last-child {
	margin-right: 0PX;
}

.inst2 {
	width: 10PX;
	height: 10PX;
	margin-right: 8PX;
	background: #fff;
	border-radius: 50%;
	cursor: pointer;
}

.inst2:last-child {
	margin-right: 0PX;
}

.instActv {
	border: 1PX solid #15F0F0;
	background: #00F0F1;
}

.instActv2 {
	width: 25PX;
	height: 10PX;
	border-radius: 15PX;
}

.instBox_text_disp {
	width: 1200PX;
	display: flex;
	justify-content: space-between;
	padding: 0 20PX;
	align-items: center;
}
</style>